import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Lenis from '@studio-freight/lenis'

import Endpoint from '../../config/endpoint';

import i18n from '../../util/i18n';
import Context from '../../util/context';
import HTTP from '../../util/http';

import Home01 from '../../view/page/home01';
import Home02 from '../../view/page/home02';
import Home03 from '../../view/page/home03';
import Home04 from '../../view/page/home04';
import Home05 from '../../view/page/home05';
import Home06 from '../../view/page/home06';
import Home07 from '../../view/page/home07';
import Home08 from '../../view/page/home08';
import Home09 from '../../view/page/home09';
import Home10 from '../../view/page/home10';
import Header from '../../view/component/header';
import Footer from '../../view/component/footer';

const Controller = props => 
{
    const [isMounted, setMounted] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [pricingData, setPricingData] = useState({});
    const [countryList, setCountryList] = useState([]);
    const [zoneList, setZoneList] = useState([]);
    const [country, setCountry] = useState("sa");
    const [zone, setZone] = useState("659db73e90254d5addbf54a5");
    const [lenis] = useState(new Lenis());
    
    const context = useContext(Context);
    const navigate = useNavigate();

    const setLenis = () =>
    {
        function raf (time)
        {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }

        requestAnimationFrame(raf);
    };

    setLenis();

    const changeLang = () =>
    {
        if(context?.prefs?.lang === "ar")
        {
            context?.setPrefs({"lang": "en"});            

            i18n.changeLanguage("en");
        }
        else
        {
            context?.setPrefs({"lang": "ar"});            

            i18n.changeLanguage("ar");
        }
    };

    const showIntroVideoDialog = () =>
    {
        context?.setDialog("IntroVideo", i18n.t("page.intro_video"), "bg-secondary-lightest", "md");
    };

    const showLoginDialog = () =>
    {
        if(context?.prefs?.is_logged_in !== "1")
        {
            context?.setDialog("Login", i18n.t("page.login"), "bg-secondary-lightest", "sm");
        }
        else
        {
            navigate("/directory");
        }
    };

    const showSignupDialog = (persona, subscriptionCode = undefined) =>
    {
        if(context?.prefs?.is_logged_in !== "1")
        {
            const dialogTitle = persona?.toLowerCase() === "captain" ? i18n.t("page.signup_as_captain") : i18n.t("page.signup_as_merchant");

            context?.setDialog("Signup", dialogTitle, "bg-secondary-lightest", "sm", false, false, true, false, {persona:persona, subscription_code:subscriptionCode, merchant_code:null, invitation_code:null, email:null, action:null});
        }
        else
        {
            navigate("/directory");
        }        
    };

    const showPasswordResetDialog = (persona) =>
    {
        if(context?.prefs?.is_logged_in !== "1")
        {
            context?.setDialog("PasswordReset", i18n.t("page.reset_password"), "bg-secondary-lightest", "sm");
        }
        else
        {
            navigate("/directory");
        }
    };

    useEffect(() =>
    {
        let params =
        {
            pricing_code:"subscription"
        };

        isMounted && HTTP.get(Endpoint?.cms?.pricing?.data, params).then(response =>
        {
            setPricingData(response?.data?.info);

            params = {};

            isMounted && HTTP.get(Endpoint?.cms?.country?.list, params).then(response =>
            {
                setCountryList(response?.data?.list);

                params = 
                {
                    country_code: "sa",
                    type_code: "public",
                    set_type: "full"
                };

                isMounted && HTTP.get(Endpoint?.oms?.zone?.list, params).then(response =>
                {
                    setZoneList(response?.data?.list);
                    
                    setLoading(false);
                });
            });
        });
    }, [isMounted]);
    
    useEffect(() =>
    {  
        const checkConnection = (isOnline) =>
        {
            if(!isOnline)
            {
                navigate("/error/disconnected");
            }     
        };

        window.addEventListener('online', () => checkConnection(true));
        window.addEventListener('offline', () => checkConnection(false));

        return () =>
        {
            window.removeEventListener('online', () => checkConnection(true));
            window.removeEventListener('offline', () => checkConnection(false));
        };
    }, [navigate]); 

    useEffect(() =>
    {    
        window.scrollTo(0, 0);

        document.title = i18n.t('app.name') + " | " + i18n.t('page.home');  
        
        return () =>
        {
            setMounted(false);
        };

    }, []);

    return (
        <>
            <Header
                changeLang={changeLang}
                showLoginDialog={showLoginDialog}
                showCaptainSignUpDialog={() => showSignupDialog("captain")}
                showMerchantSignUpDialog={() => showSignupDialog("owner", "basic")}
                prefs={context?.prefs}
                isHome={true}
                isFixed={true}
                isMinimal={false}
            />          
            <Home01
                showIntroVideoDialog={() => showIntroVideoDialog()}
                prefs={context?.prefs}
            />      
            <Home02
                prefs={context?.prefs}
            />  
            <Home03
                prefs={context?.prefs}
            />             
            <Home04
                showSignupDialog={() => showSignupDialog("owner", "basic")}
                prefs={context?.prefs}
            />              
            <Home05
                prefs={context?.prefs}
            />
            <Home06
                prefs={context?.prefs}
            />                        
            <Home07
                prefs={context?.prefs}
            />
            <Home08
                prefs={context?.prefs}
            />
            <Home09
                prefs={context?.prefs}
                isLoading={isLoading}
                pricingData={pricingData}
                showSignupDialog={(subscriptionCode) => showSignupDialog("owner", subscriptionCode)}
            />
            <Home10
                country={country}
                zone={zone}
                setCountry={(newCountry) => {if(newCountry !== country) setCountry(newCountry)}}
                setZone={(newZone) => {if(newZone !== zone) setZone(newZone)}}
                countryList={countryList?.filter((country) => country?.is_served)?.map((country) => {return {title:country?.[`name_${context?.prefs?.lang}`], value:country?.country_code}})}
                zoneList={zoneList?.map((zone) => {return {title:zone?.[`name_${context?.prefs?.lang}`], value:zone?.zone_id, geofence:zone?.geofence, center_lat:zone?.center_lat, center_long:zone?.center_long}})}
                prefs={context?.prefs}
                isLoading={isLoading}
            />                                                                                                   
            <Footer
                showLoginDialog={showLoginDialog}
                showCaptainSignUpDialog={() => showSignupDialog("captain")}
                showMerchantSignUpDialog={() => showSignupDialog("owner", "basic")}
                showPasswordResetDialog={showPasswordResetDialog}
                prefs={context?.prefs}
            />
        </>
    );
}

export default Controller;