import { useState } from 'react';
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import Constant from '../../config/constant';

import i18n from '../../util/i18n';

import Button from '../component/button';
import InputText from '../component/inputText';
import InputDropdown from '../component/inputDropdown';
import InputFile from '../component/inputFile';

const View = (props) =>
{
    const [data, setData] = useState({});

    const update = (key, value) =>
    {
        let temp = {...data};

        temp[key] = value;

        setData(temp);
    };

    return(
        <div className="font-secondary text-sm text-neutral-low-dark">
            <div className="flex w-full">
                <div className={`w-full text-center transition-all duration-normal ease-in-out ${(props?.tab === "account") ? "text-neutral-low-darkest" : "bg-neutral-high-light cursor-pointer text-neutral-low-darkest"}`} onClick={() => {props?.setTab("account")}}>
                    <div className="m-auto p-4">{i18n.t("common.account_details")}</div>
                </div>
                <div className={`w-full text-center transition-all duration-normal ease-in-out ${(props?.tab === "personal") ? "text-neutral-low-darkest" : "bg-neutral-high-light cursor-pointer text-neutral-low-darkest"}`} onClick={() => {props?.setTab("personal")}}>
                    <div className="m-auto p-4">{i18n.t("common.personal_info")}</div>
                </div>
                <div className={`w-full text-center transition-all duration-normal ease-in-out ${(props?.tab === "password") ? "text-neutral-low-darkest" : "bg-neutral-high-light cursor-pointer text-neutral-low-darkest"}`} onClick={() => {props?.setTab("password")}}>
                    <div className="m-auto p-4">{i18n.t("common.password")}</div>
                </div>
            </div>            
            <div className="px-7 pt-10 pb-7 font-secondary text-sm">
                {(props?.tab === "account")
                ?
                    <div className="m-0"> 
                        <div className="mb-7">
                            <InputText 
                                type={"text"}
                                enableAutoComplete={false}
                                label={i18n.t("common.email")}
                                placeholder={"email@example.com"}
                                value={data?.email ?? props?.data?.email}
                                errorMsg={props?.errors?.email}
                                onChange={(e) => {update("email", e?.target?.value)}}
                            />
                        </div>

                        <div className="mb-7">
                            <div className="pb-2">
                                {i18n.t("common.mobile")}
                            </div>
                            <div dir="ltr" className="flex gap-2">
                                <div className="w-[150px] text-sm">
                                    <InputDropdown
                                        list={props?.countryList?.filter((country) => country?.is_served)?.map((country) => {return {title:`+${country?.mobile_code}`, value:country?.mobile_code}})}
                                        listValue={data?.mobile_code ?? props?.data?.mobile_code}
                                        searchInputPlaceholder={i18n.t("common.search_generic")}
                                        isDisabled={props?.isLoading}
                                        isListSorted={true}
                                        isListReversed={false}
                                        isSearchable={false}
                                        isCompact={false}
                                        handleValueUpdate={(value) => {update("mobile_code", value)}}
                                    />                        
                                </div>                    
                                <div className="w-full">
                                    <InputText 
                                        type={"text"}
                                        errorClassName={`text-status-danger-regular ${props?.prefs?.dir === "rtl" ? "text-end" : "text-start"}`}
                                        enableAutoComplete={false}
                                        placeholder={"5XXXXXXXXX"}
                                        value={data?.mobile ?? props?.data?.mobile}
                                        onChange={(e) => {update("mobile", e?.target?.value)}}
                                    />
                                </div>
                            </div> 
                            <AnimatePresence>
                            {props?.errors?.mobile && (
                                <motion.div 
                                    initial={{ height:0, opacity:0 }}
                                    animate={{ height: "auto", opacity:1 }}
                                    exit={{ height:0, opacity:0 }}
                                    transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                    className={`text-status-danger-regular font-secondary text-sm mt-2`}
                                >
                                    {props?.errors?.mobile}
                                </motion.div>
                            )}
                            </AnimatePresence>   
                        </div>                                            
                    </div>
                :
                (props?.tab === "personal")
                ?
                    <div className="m-0">
                        <div className="mb-7">
                            <div className="grid grid-cols-2 gap-5 mb-2">
                                <InputText 
                                    type={"text"}
                                    enableAutoComplete={false}
                                    label={i18n.t("common.first_name")}
                                    placeholder={i18n.t("common.first_name")}
                                    value={data?.first_name ?? props?.data?.first_name}
                                    onChange={(e) => {update("first_name", e?.target?.value)}}
                                /> 
                                <InputText 
                                    type={"text"}
                                    enableAutoComplete={false}
                                    label={i18n.t("common.last_name")}
                                    placeholder={i18n.t("common.last_name")}
                                    value={data?.last_name ?? props?.data?.last_name}
                                    onChange={(e) => {update("last_name", e?.target?.value)}}
                                />             
                            </div>
                            <AnimatePresence>
                                {props?.errors?.name && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                        className={`text-status-danger-regular font-secondary text-sm`}
                                    >
                                        {props?.errors?.name}
                                    </motion.div>
                                )}
                            </AnimatePresence>  
                        </div>

                        <div className="mb-7">
                            <div className="mb-2">
                                {i18n.t("common.gender")}
                            </div>
                            <InputDropdown
                                list={[{title:i18n.t("common.male"), value:"male"}, {title:i18n.t("common.female"), value:"female"}]}
                                listValue={data?.gender ?? props?.data?.gender}
                                searchInputPlaceholder={i18n.t("common.search_generic")}
                                isDisabled={props?.isLoading}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={(value) => {update("gender", value)}}
                            />
                            <AnimatePresence>
                                {props?.errors?.gender && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                        className={`text-status-danger-regular font-secondary text-sm`}
                                    >
                                        {props?.errors?.gender}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>             

                        <div className="mb-7">
                            <div className="mb-2">
                                {i18n.t("common.nationality")}
                            </div>
                            <InputDropdown
                                list={props?.countryList?.map((country) => {return {title:`${country?.[`name_${props?.prefs?.lang}`]}`, value:country?.country_code}})}
                                listValue={data?.nationality_code ?? props?.data?.nationality_code}
                                searchInputPlaceholder={i18n.t("common.search_generic")}
                                isDisabled={props?.isLoading}
                                isListSorted={true}
                                isListReversed={false}
                                isSearchable={true}
                                isCompact={true}
                                handleValueUpdate={(value) => {update("nationality_code", value)}}
                            />
                            <AnimatePresence>
                                {props?.errors?.nationality && (
                                    <motion.div 
                                        initial={{ height:0, opacity:0 }}
                                        animate={{ height: "auto", opacity:1 }}
                                        exit={{ height:0, opacity:0 }}
                                        transition={{ duration: Constant?.animation?.speed?.fastest/1000, ease: easeInOut }}                
                                        className={`text-status-danger-regular font-secondary text-sm`}
                                    >
                                        {props?.errors?.nationality}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>

                        <div className="mb-7">
                            <InputText 
                                type={"text"}
                                enableAutoComplete={false}
                                label={i18n.t("common.government_id")}
                                placeholder={i18n.t("common.government_id")}
                                value={data?.government_id ?? props?.data?.government_id}
                                errorMsg={props?.errors?.government_id}
                                onChange={(e) => {update("government_id", e?.target?.value)}}
                            />              
                        </div>  

                        <div className="mb-7">
                            <InputFile 
                                enableAutoComplete={false}
                                label={i18n.t("common.avatar_img")}
                                isDisabled={props?.isLoading}
                                errorMsg={props?.errors?.avatar_img}
                                onChange={(file) => {update("avatar_img", file)}}
                            />
                        </div>                                                                        
                    </div>
                :
                (props?.tab === "password")
                ?
                    <div className="m-0">
                        <div className="mb-7">
                            <InputText 
                                type={"password"}
                                enableAutoComplete={false}
                                label={i18n.t("common.password_old")}
                                placeholder={""}
                                errorMsg={props?.errors?.password}
                                onChange={(e) => {update("password", e?.target?.value)}}
                            /> 
                        </div>
                        <div className="mb-7">
                            <InputText 
                                type={"password"}
                                enableAutoComplete={false}
                                label={i18n.t("common.password_new")}
                                placeholder={""}
                                errorMsg={props?.errors?.password_new}
                                onChange={(e) => {update("password_new", e?.target?.value)}}
                            /> 
                        </div>
                        <div className="mb-7">
                            <InputText 
                                type={"password"}
                                enableAutoComplete={false}
                                label={i18n.t("common.password_new_confirmation")}
                                placeholder={""}
                                errorMsg={props?.errors?.password_new_confirmation}
                                onChange={(e) => {update("password_new_confirmation", e?.target?.value)}}
                            /> 
                        </div>                                                                        
                    </div>                    
                :
                    null
                }

                <div className="flex justify-end pt-5">
                    <Button type="neutral-low-solid" className="min-w-[120px]" text={i18n.t("common.save")} isLoading={props?.isLoading}  isDisabled={false} onClick={() => props?.save({...props?.data, ...data})}/>
                </div>
            </div>
        </div>
    );
}

export default View;        