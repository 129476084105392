import i18n from '../../util/i18n';

import Table from '../component/table';
import Button from '../component/button';
import InputDropdown from '../component/inputDropdown';

import AddDarkIconImage from '../../assets/vector/icon_add_dark.svg';
import AddLightIconImage from '../../assets/vector/icon_add_light.svg';

const View = (props) =>
{
    return( 
        <div className={`h-full w-full rounded-xl lg:rounded-s-none lg:rounded-e-xl bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-gradient-regular via-gradient-dark to-gradient-darkest flex flex-col`}>
            <div className="border-t-[1px] border-neutral-high-regular grow text-neutral-low-dark overflow-scroll mt-[150px] lg:mt-[92px]" data-lenis-prevent>
                <div className="p-5 transition ease-in-out duration-normal">
                    <div className="mb-10 font-primary text-lg">
                        {i18n.t("page.dashboard_user")}
                    </div>
                    <div className="flex flex-wrap md:flex-nowrap justify-between gap-1 mb-5">
                        <div className="flex gap-2 pb-5 md:pb-0 relative">
                            <Button 
                                type="neutral-low-solid" 
                                className="min-w-[120px] h-min" 
                                text={i18n.t("page.invite_user")} 
                                icon={(props?.prefs?.theme === "dark") ? AddDarkIconImage : AddLightIconImage}
                                iconHover={(props?.prefs?.theme === "light") ? AddDarkIconImage : AddLightIconImage} 
                                isLoading={props?.isLoading} 
                                onClick={props?.inviteUser}
                            />
                            <Button 
                                type="neutral-low-solid" 
                                className="min-w-[120px] h-min" 
                                text={i18n.t("page.skills")} 
                                isLoading={props?.isLoading} 
                                onClick={props?.ManageUserSkills}
                            />                            
                        </div>
                        <div className="flex gap-2 justify-end w-full">
                            <InputDropdown
                                theme={props?.prefs?.theme}
                                selectorClassName={"text-neutral-low-dark border-neutral-high-regular bg-neutral-high-light active:bg-neutral-high-light hover:bg-neutral-high-light mb-2 w-full md:w-[180px]"}
                                listClassName={"bg-neutral-high-lightest"}
                                itemClassName={"text-neutral-low-dark bg-neutral-high-lightest hover:bg-neutral-high-light border-neutral-high-light"}
                                selectorPlaceholder={i18n.t("common.filter_by")}
                                list={[
                                        {title:i18n.t("common.captain"), value:"captain"}, 
                                        {title:i18n.t("common.observer"), value:"observer"}, 
                                        {title:i18n.t("common.maintainer"), value:"maintainer"}, 
                                        {title:i18n.t("common.owner"), value:"owner"}
                                    ]}
                                listValue={props?.listFilterValue}
                                isDisabled={props?.isLoading}
                                isListSorted={false}
                                isListReversed={false}
                                isSearchable={false}
                                isCompact={false}
                                handleValueUpdate={props?.setListFilterValue}
                            />                                 
                        </div>
                    </div>
                    <div className="mb-5">
                        <Table
                            labels={
                            [
                                {order:1, id:"name", title:i18n.t("common.full_name"), dialogID:"UserInfo", dialogTitle:i18n.t("page.user_info"), dialogDataID:"user_id", isStatusIndicator:false},
                                {order:2, id:"email", title:i18n.t("common.email"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false},
                                {order:3, id:"is_active", title:i18n.t("common.status"), dialogID:null, dialogTitle:null, dialogDataID:null, isStatusIndicator:false}
                            ]}
                            list={props?.list}
                            prefs={props?.prefs}
                            showDialog={props?.showDialog}
                            isLoading={props?.isLoading}
                        />            
                    </div>
                    <div className="flex justify-between gap-1 pt-4">
                        <div className="p-0">
                            <Button type="neutral-low-outline" className="min-w-[120px]" text={i18n.t("common.prev")} isLoading={props?.isLoading} isDisabled={props?.listPage <= 1} onClick={props?.goPrev}/>
                        </div>
                        <div className="p-0">
                            <Button type="neutral-low-outline" className="min-w-[120px]" text={i18n.t("common.next")} isLoading={props?.isLoading} isDisabled={props?.isLoading || props?.list?.length === 0 || props?.list?.length < props?.recordsPerPage} onClick={props?.goNext}/>
                        </div> 
                    </div>
                </div>
            </div>
        </div>   
    );
}

export default View;    